<template>

	<div class="productDealbox">

		<div class="top" style="background: #fff;padding: 15px;margin-bottom: 20px;">
			<el-tabs v-model="listState" type="card" @tab-click="handleClick">
				<el-tab-pane v-for="item in listStateList" :key="item.id" :label="item.value" :name="item.id+''"></el-tab-pane>
			</el-tabs>
			<div v-show="listState==0">
				<statistics-time :unShowType='unShowType' @getRangeTime='getRangeTime' v-show="listState==0"></statistics-time>
			</div>

			<div v-show="listState==1" class="filter-container">
				<div class="filter-item">
					<label class="label">分组：</label>
					<el-select v-model="groupId" @change="stateChange" clearable filterable> 
						<el-option v-for="item in groups" :key="item.Id" :label="item.GroupName" :value="item.Id">
						</el-option>
					</el-select>
				</div>

				<div class="filter-item">
					<label class="label">状态：</label>
					<el-select v-model="StateId" @change="stateChange" clearable filterable>
						<el-option v-for="item in Statelist" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="filter-item">
					<statistics-time :unShowType='unShowType' @getRangeTime='getRangeTime1' v-show="listState==1"></statistics-time>
				</div>
				<div class="contents">
					<div class="con_item" style="display: flex;">
						<span>销售指标：</span>
						<div class="con_items" style="display: flex;flex-wrap: wrap;">
							<div class="item_detail">
								<el-checkbox :label="1" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(1)">加购人数</el-checkbox>
								<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[2]" placement="top-start">
									<i class="el-icon-warning-outline" style="margin-left:8px"></i>
								</el-tooltip>
							</div>
							<div class="item_detail">
								<el-checkbox :label="2" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(2)">加购件数</el-checkbox>
								<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[3]" placement="top-start">
									<i class="el-icon-warning-outline" style="margin-left:8px"></i>
								</el-tooltip>
							</div>
							<div class="item_detail">
								<el-checkbox :label="3" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(3)">下单人数</el-checkbox>
								<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[4]" placement="top-start">
									<i class="el-icon-warning-outline" style="margin-left:8px"></i>
								</el-tooltip>
							</div>
							<div class="item_detail">
								<el-checkbox :label="4" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(4)">下单件数</el-checkbox>
								<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[5]" placement="top-start">
									<i class="el-icon-warning-outline" style="margin-left:8px"></i>
								</el-tooltip>
							</div>
							<div class="item_detail">
								<el-checkbox :label="5" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(5)">下单金额</el-checkbox>
								<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[6]" placement="top-start">
									<i class="el-icon-warning-outline" style="margin-left:8px"></i>
								</el-tooltip>
							</div>
							<div class="item_detail">
								<el-checkbox :label="6" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(6)">加购-下单转化率</el-checkbox>
								<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[7]" placement="top-start">
									<i class="el-icon-warning-outline" style="margin-left:8px"></i>
								</el-tooltip>
							</div>
							<div class="item_detail">
								<el-checkbox :label="7" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(7)">支付人数</el-checkbox>
								<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[8]" placement="top-start">
									<i class="el-icon-warning-outline" style="margin-left:8px"></i>
								</el-tooltip>
							</div>
							<div class="item_detail">
								<el-checkbox :label="8" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(8)">支付件数</el-checkbox>
								<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[9]" placement="top-start">
									<i class="el-icon-warning-outline" style="margin-left:8px"></i>
								</el-tooltip>
							</div>
							<div class="item_detail">
								<el-checkbox :label="9" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(9)">支付金额</el-checkbox>
								<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[10]" placement="top-start">
									<i class="el-icon-warning-outline" style="margin-left:8px"></i>
								</el-tooltip>
							</div>
							<div class="item_detail">
								<el-checkbox :label="10" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(10)">下单-支付转化率</el-checkbox>
								<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[11]" placement="top-start">
									<i class="el-icon-warning-outline" style="margin-left:8px"></i>
								</el-tooltip>
							</div>
							<div class="item_detail">
								<el-checkbox :label="11" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(11)">客单价</el-checkbox>
								<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[1]" placement="top-start">
									<i class="el-icon-warning-outline" style="margin-left:8px"></i>
								</el-tooltip>
							</div>
						</div>
					</div>
					<div class="con_item" style="display: flex;">
						<span>服务指标：</span>
						<div class="con_items" style="display: flex;flex-wrap: wrap;">
							<div class="item_detail">
								<el-checkbox :label="12" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(12)">申请退款单数</el-checkbox>
								<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[13]" placement="top-start">
									<i class="el-icon-warning-outline" style="margin-left:8px"></i>
								</el-tooltip>
							</div>
							<div class="item_detail">
								<el-checkbox :label="13" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(13)">申请退款人数</el-checkbox>
								<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[12]" placement="top-start">
									<i class="el-icon-warning-outline" style="margin-left:8px"></i>
								</el-tooltip>
							</div>
							<div class="item_detail">
								<el-checkbox :label="14" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(14)">成功退款单数</el-checkbox>
								<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[15]" placement="top-start">
									<i class="el-icon-warning-outline" style="margin-left:8px"></i>
								</el-tooltip>
							</div>
							<div class="item_detail">
								<el-checkbox :label="15" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(15)">成功退款人数</el-checkbox>
								<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[14]" placement="top-start">
									<i class="el-icon-warning-outline" style="margin-left:8px"></i>
								</el-tooltip>
							</div>
							<div class="item_detail">
								<el-checkbox :label="16" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(16)">成功退款金额</el-checkbox>
								<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[16]" placement="top-start">
									<i class="el-icon-warning-outline" style="margin-left:8px"></i>
								</el-tooltip>
							</div>
							<div class="item_detail">
								<el-checkbox :label="17" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(17)">退款率</el-checkbox>
								<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[17]" placement="top-start">
									<i class="el-icon-warning-outline" style="margin-left:8px"></i>
								</el-tooltip>
							</div>
						</div>
					</div>
					<div style="color: #C5C8CE;font-size: 13px;padding-left:67px;">最多只能同时选择5个指标</div>
				</div>
			</div>

		</div>
		<!-- //商品交易概况 -->
		<div class="overview" v-if="listState == 0" v-loading="overLoading">
			<div class="title" style="margin-bottom:10px;">商品交易概况</div>
			<div class="interview">
				<div class="left">
					商品购买
				</div>
				<div class="right">
					<div class="item">
						<span class="lable">动销商品数</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[0]" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.SellProductNum}}</span>
						<div class="compare" v-if="timeType!=0">
							<span>{{currentKeyword}}</span>
							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.SellProductNum,PrevOverview.SellProductNum)"></svg-icon>
							<span v-if="PrevOverview.SellProductNum">{{commentDatafun(CurrentOverview.SellProductNum,PrevOverview.SellProductNum)}}%</span>
							<span v-if="PrevOverview.SellProductNum==0&&CurrentOverview.SellProductNum==0">0%</span>
							<span v-if="PrevOverview.SellProductNum==0&&CurrentOverview.SellProductNum!=0">100%</span>

						</div>
					</div>
					<div class="item">
						<span class="lable">客单价</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[1]" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.CustomerUnitPrice}}</span>
						<div class="compare"  v-if="timeType!=0">
							<span>{{currentKeyword}}</span>

							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.CustomerUnitPrice,PrevOverview.CustomerUnitPrice)"></svg-icon>
							<span v-if="PrevOverview.CustomerUnitPrice">{{commentDatafun(CurrentOverview.CustomerUnitPrice,PrevOverview.CustomerUnitPrice)}}%</span>
							<span v-if="PrevOverview.CustomerUnitPrice==0&&CurrentOverview.CustomerUnitPrice==0">0%</span>
							<span v-if="PrevOverview.CustomerUnitPrice==0&&CurrentOverview.CustomerUnitPrice!=0">100%</span>
						</div>
					</div>
					<div class="item">
						<span class="lable">加购人数</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[2]" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.AddToCartMemberCount}}</span>
						<div class="compare"  v-if="timeType!=0">
							<span>{{currentKeyword}}</span>
							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.AddToCartMemberCount,PrevOverview.AddToCartMemberCount)"></svg-icon>
							<span v-if="PrevOverview.AddToCartMemberCount">{{commentDatafun(CurrentOverview.AddToCartMemberCount,PrevOverview.AddToCartMemberCount)}}%</span>
							<span v-if="PrevOverview.AddToCartMemberCount==0&&CurrentOverview.AddToCartMemberCount==0">0%</span>
							<span v-if="PrevOverview.AddToCartMemberCount==0&&CurrentOverview.AddToCartMemberCount!=0">100%</span>
						</div>
					</div>
					<div class="item">
						<span class="lable">加购件数</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[3]" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.AddToCartProductCount}}</span>
						<div class="compare" v-if="timeType!=0">
							<span>{{currentKeyword}}</span>
							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.AddToCartProductCount,PrevOverview.AddToCartProductCount)"></svg-icon>
							<span v-if="PrevOverview.AddToCartProductCount">{{commentDatafun(CurrentOverview.AddToCartProductCount,PrevOverview.AddToCartProductCount)}}%</span>
							<span v-if="PrevOverview.AddToCartProductCount==0&&CurrentOverview.AddToCartProductCount==0">0%</span>
							<span v-if="PrevOverview.AddToCartProductCount==0&&CurrentOverview.AddToCartProductCount!=0">100%</span>
						</div>
					</div>
					<div class="item">
						<span class="lable">下单人数</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[4]" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.SubmitOrderMemberCount}}</span>
						<div class="compare" v-if="timeType!=0">
							<span>{{currentKeyword}}</span>
							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.SubmitOrderMemberCount,PrevOverview.SubmitOrderMemberCount)"></svg-icon>
							<span v-if="PrevOverview.SubmitOrderMemberCount">{{commentDatafun(CurrentOverview.SubmitOrderMemberCount,PrevOverview.SubmitOrderMemberCount)}}%</span>
							<span v-if="PrevOverview.SubmitOrderMemberCount==0&&CurrentOverview.SubmitOrderMemberCount==0">0%</span>
							<span v-if="PrevOverview.SubmitOrderMemberCount==0&&CurrentOverview.SubmitOrderMemberCount!=0">100%</span>
						</div>
					</div>
					<div class="item">
						<span class="lable">下单件数</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[5]" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.SubmitOrderProductCount}}</span>
						<div class="compare"  v-if="timeType!=0">
							<span>{{currentKeyword}}</span>
							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.SubmitOrderProductCount,PrevOverview.SubmitOrderProductCount)"></svg-icon>
							<span v-if="PrevOverview.SubmitOrderProductCount">{{commentDatafun(CurrentOverview.SubmitOrderProductCount,PrevOverview.SubmitOrderProductCount)}}%</span>
							<span v-if="PrevOverview.SubmitOrderProductCount==0&&CurrentOverview.SubmitOrderProductCount==0">0%</span>
							<span v-if="PrevOverview.SubmitOrderProductCount==0&&CurrentOverview.SubmitOrderProductCount!=0">100%</span>
						</div>
					</div>

					<div class="item">
						<span class="lable">下单金额</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[6]" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.SubmitOrderProductMoney}}</span>
						<div class="compare"  v-if="timeType!=0">
							<span>{{currentKeyword}}</span>
							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.SubmitOrderProductMoney,PrevOverview.SubmitOrderProductMoney)"></svg-icon>
							<span v-if="PrevOverview.SubmitOrderProductMoney">{{commentDatafun(CurrentOverview.SubmitOrderProductMoney,PrevOverview.SubmitOrderProductMoney)}}%</span>
							<span v-if="PrevOverview.SubmitOrderProductMoney==0&&CurrentOverview.SubmitOrderProductMoney==0">0%</span>
							<span v-if="PrevOverview.SubmitOrderProductMoney==0&&CurrentOverview.SubmitOrderProductMoney!=0">100%</span>
						</div>
					</div>
					<div class="item">
						<span class="lable">加购-下单转化率</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[7]" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.AddToCartMemberCountAndSubmitOrderMemberCountConversionRate}}%</span>
						<div class="compare"  v-if="timeType!=0">
							<span>{{currentKeyword}}</span>
							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.AddToCartMemberCountAndSubmitOrderMemberCountConversionRate,PrevOverview.AddToCartMemberCountAndSubmitOrderMemberCountConversionRate)"></svg-icon>
							<span>{{Math.abs(CurrentOverview.AddToCartMemberCountAndSubmitOrderMemberCountConversionRate-PrevOverview.AddToCartMemberCountAndSubmitOrderMemberCountConversionRate).toFixed(2)}}%</span>
							
						</div>
					</div>
					<div class="item">
						<span class="lable">支付人数</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[8]" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.PayOrderMemberCount}}</span>
						<div class="compare"  v-if="timeType!=0">
							<span>{{currentKeyword}}</span>
							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.PayOrderMemberCount,PrevOverview.PayOrderMemberCount)"></svg-icon>
							<span v-if="PrevOverview.PayOrderMemberCount">{{commentDatafun(CurrentOverview.PayOrderMemberCount,PrevOverview.PayOrderMemberCount)}}%</span>
							<span v-if="PrevOverview.PayOrderMemberCount==0&&CurrentOverview.PayOrderMemberCount==0">0%</span>
							<span v-if="PrevOverview.PayOrderMemberCount==0&&CurrentOverview.PayOrderMemberCount!=0">100%</span>
						</div>
					</div>
					<div class="item">
						<span class="lable">支付件数</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[9]" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.PayOrderProductCount}}</span>
						<div class="compare"  v-if="timeType!=0">
							<span>{{currentKeyword}}</span>
							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.PayOrderProductCount,PrevOverview.PayOrderProductCount)"></svg-icon>
							<span v-if="PrevOverview.PayOrderProductCount">{{commentDatafun(CurrentOverview.PayOrderProductCount,PrevOverview.PayOrderProductCount)}}%</span>
							<span v-if="PrevOverview.PayOrderProductCount==0&&CurrentOverview.PayOrderProductCount==0">0%</span>
							<span v-if="PrevOverview.PayOrderProductCount==0&&CurrentOverview.PayOrderProductCount!=0">100%</span>
						</div>
					</div>
					<div class="item">
						<span class="lable">支付金额</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[10]" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.PayOrderProductMoney}}</span>
						<div class="compare" v-if="timeType!=0">
							<span>{{currentKeyword}}</span>
							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.PayOrderProductMoney,PrevOverview.PayOrderProductMoney)"></svg-icon>
							<span v-if="PrevOverview.PayOrderProductMoney">{{commentDatafun(CurrentOverview.PayOrderProductMoney,PrevOverview.PayOrderProductMoney)}}%</span>
							<span v-if="PrevOverview.PayOrderProductMoney==0&&CurrentOverview.PayOrderProductMoney==0">0%</span>
							<span v-if="PrevOverview.PayOrderProductMoney==0&&CurrentOverview.PayOrderProductMoney!=0">100%</span>
						</div>
					</div>
					<div class="item">
						<span class="lable">下单-支付转化率</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[11]" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.SubmitOrderMemberCountAndPayOrderMemberCountConversionRate}}%</span>
						<div class="compare"  v-if="timeType!=0">
							<span>{{currentKeyword}}</span>
							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.SubmitOrderMemberCountAndPayOrderMemberCountConversionRate,PrevOverview.SubmitOrderMemberCountAndPayOrderMemberCountConversionRate)"></svg-icon>
							<span >{{Math.abs(CurrentOverview.SubmitOrderMemberCountAndPayOrderMemberCountConversionRate-PrevOverview.SubmitOrderMemberCountAndPayOrderMemberCountConversionRate).toFixed(2)}}%</span>
							
						</div>
					</div>
				</div>
			</div>
			<div class="interview flow">
				<div class="left">
					商品退款
				</div>
				<div class="right">
					<div class="item">
						<span class="lable">申请退款人数</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[12]" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.ApplyRefundOrderMemberCount}}</span>
						<div class="compare"  v-if="timeType!=0">
							<span>{{currentKeyword}}</span>
							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.ApplyRefundOrderMemberCount,PrevOverview.ApplyRefundOrderMemberCount)"></svg-icon>
							<span v-if="PrevOverview.ApplyRefundOrderMemberCount">{{commentDatafun(CurrentOverview.ApplyRefundOrderMemberCount,PrevOverview.ApplyRefundOrderMemberCount)}}%</span>
							<span v-if="PrevOverview.ApplyRefundOrderMemberCount==0&&CurrentOverview.ApplyRefundOrderMemberCount==0">0%</span>
							<span v-if="PrevOverview.ApplyRefundOrderMemberCount==0&&CurrentOverview.ApplyRefundOrderMemberCount!=0">100%</span>
						</div>
					</div>
					<div class="item">
						<span class="lable">申请退款单数</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[13]" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.ApplyRefundOrderCount}}</span>
						<div class="compare"  v-if="timeType!=0">
							<span>{{currentKeyword}}</span>
							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.ApplyRefundOrderCount,PrevOverview.ApplyRefundOrderCount)"></svg-icon>
							<span v-if="PrevOverview.ApplyRefundOrderCount">{{commentDatafun(CurrentOverview.ApplyRefundOrderCount,PrevOverview.ApplyRefundOrderCount)}}%</span>
							<span v-if="PrevOverview.ApplyRefundOrderCount==0&&CurrentOverview.ApplyRefundOrderCount==0">0%</span>
							<span v-if="PrevOverview.ApplyRefundOrderCount==0&&CurrentOverview.ApplyRefundOrderCount!=0">100%</span>
						</div>
					</div>
					<div class="item">
						<span class="lable">成功退款人数</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[14]" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.RefundOrderMemberCount}}</span>
						<div class="compare" v-if="timeType!=0">
							<span>{{currentKeyword}}</span>
							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.RefundOrderMemberCount,PrevOverview.RefundOrderMemberCount)"></svg-icon>
							<span v-if="PrevOverview.RefundOrderMemberCount">{{commentDatafun(CurrentOverview.RefundOrderMemberCount,PrevOverview.RefundOrderMemberCount)}}%</span>
							<span v-if="PrevOverview.RefundOrderMemberCount==0&&CurrentOverview.RefundOrderMemberCount==0">0%</span>
							<span v-if="PrevOverview.RefundOrderMemberCount==0&&CurrentOverview.RefundOrderMemberCount!=0">100%</span>
						</div>
					</div>
					<div class="item">
						<span class="lable">成功退款单数</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[15]" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.RefundOrderCount}}</span>
						<div class="compare"  v-if="timeType!=0">
							<span>{{currentKeyword}}</span>
							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.RefundOrderCount,PrevOverview.RefundOrderCount)"></svg-icon>
							<span v-if="PrevOverview.RefundOrderCount">{{commentDatafun(CurrentOverview.RefundOrderCount,PrevOverview.RefundOrderCount)}}%</span>
							<span v-if="PrevOverview.RefundOrderCount==0&&CurrentOverview.RefundOrderCount==0">0%</span>
							<span v-if="PrevOverview.RefundOrderCount==0&&CurrentOverview.RefundOrderCount!=0">100%</span>
						</div>
					</div>
					<div class="item">
						<span class="lable">成功退款金额</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[16]" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.RefundOrderProductMoney}}</span>
						<div class="compare"  v-if="timeType!=0">
							<span>{{currentKeyword}}</span>
							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.RefundOrderProductMoney,PrevOverview.RefundOrderProductMoney)"></svg-icon>
							<span v-if="PrevOverview.RefundOrderProductMoney">{{commentDatafun(CurrentOverview.RefundOrderProductMoney,PrevOverview.RefundOrderProductMoney)}}%</span>
							<span v-if="PrevOverview.RefundOrderProductMoney==0&&CurrentOverview.RefundOrderProductMoney==0">0%</span>
							<span v-if="PrevOverview.RefundOrderProductMoney==0&&CurrentOverview.RefundOrderProductMoney!=0">100%</span>
						</div>
					</div>
					<div class="item">
						<span class="lable">退款率</span>
						<el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="checkTooltip[17]" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
						<br>
						<span class="num">{{CurrentOverview.RefundRate}}%</span>
						<div class="compare"  v-if="timeType!=0">
							<span>{{currentKeyword}}</span>
							<svg-icon :icon-class="commentArrowsfun(CurrentOverview.RefundRate,PrevOverview.RefundRate)"></svg-icon>
							<span>{{Math.abs(CurrentOverview.RefundRate-PrevOverview.RefundRate).toFixed(2)}}%</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="dealAnlay" v-if="listState == 1" style="padding: 15px;background: #fff;">
			<el-input placeholder="搜索" style="width:300px;margin:10px 0;" v-model="keyWard" @input="inputFun">
				<i slot="prefix" class="el-input__icon el-icon-search"></i>
			</el-input>
			<div class="table">
				<el-table :data="tableData" style="width: 100%" :row-key="rowKey" v-loading="tableLoading"   @sort-change="sortChange">
					<el-table-column label="商品信息" width="200px">
						<template slot-scope="scope">
							<div class="infos">
								<img :src="imgUrl+scope.row.ImgUrl" style="width: 60px;height: 60px;margin-right:8px;" />
								<div class="detailinfo">
									<div class="title">{{scope.row.ProductName}}</div>
									<div class="price" style="color: #E51C23">￥{{scope.row.ProductPrice}}</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="AddToCartMemberCount" v-if="Checks.includes(1)" sortable label="加购人数"></el-table-column>
					<el-table-column prop="AddToCartProductCount" v-if="Checks.includes(2)" sortable label="加购件数"></el-table-column>
					<el-table-column prop="SubmitOrderMemberCount" v-if="Checks.includes(3)" sortable label="下单人数"></el-table-column>
					<el-table-column prop="SubmitOrderProductCount" v-if="Checks.includes(4)" sortable label="下单件数"></el-table-column>
					<el-table-column prop="SubmitOrderMoney" v-if="Checks.includes(5)" sortable label="下单金额"></el-table-column>
					<el-table-column prop="AddCartToSubmitRate" v-if="Checks.includes(6)" sortable label="加购-下单转化率">
						<template slot-scope="scope">
							<span>{{scope.row.AddCartToSubmitRate}}</span>
							<span v-if="scope.row.AddCartToSubmitRate>0">%</span>
						</template>
					</el-table-column>
					<el-table-column prop="PayOrderMemberCount" v-if="Checks.includes(7)" sortable label="支付人数"></el-table-column>
					<el-table-column prop="PayOrderProductCount" v-if="Checks.includes(8)" sortable label="支付件数"></el-table-column>
					<el-table-column prop="PayOrderMoney" v-if="Checks.includes(9)" sortable label="支付金额"></el-table-column>
					<el-table-column prop="SubmitToPayRate" v-if="Checks.includes(10)" sortable label="下单-支付转化率">
						<template slot-scope="scope">
							<span>{{scope.row.SubmitToPayRate}}</span>
							<span v-if="scope.row.SubmitToPayRate>0">%</span>
						</template>
					</el-table-column>
					<el-table-column prop="UnitCustomerPrice" v-if="Checks.includes(11)" sortable label="客单价"></el-table-column>
					<el-table-column prop="ApplyRefundOrderCount" v-if="Checks.includes(12)" sortable label="申请退款单数"></el-table-column>
					<el-table-column prop="ApplyRefundOrderMemberCount" v-if="Checks.includes(13)" sortable label="申请退款人数"></el-table-column>
					<el-table-column prop="RefundOrderCount" v-if="Checks.includes(14)" sortable label="成功退款单数"></el-table-column>
					<el-table-column prop="RefundOrderMemberCount" v-if="Checks.includes(15)" sortable label="成功退款人数"></el-table-column>
					<el-table-column prop="RefundOrderMoney" v-if="Checks.includes(16)" sortable label="成功退款金额"></el-table-column>
					<el-table-column prop="RefundRate" v-if="Checks.includes(17)" sortable label="退款率">
						<template slot-scope="scope">
							<span>{{scope.row.RefundRate}}</span>
							<span v-if="scope.row.RefundRate>0">%</span>
						</template>
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button type="text" style="color: #0040D0;" @click="SingelAnaly(scope.row)">单品分析</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination style="margin-top:20px;float:right;" v-if="page.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[10, 20, 30, 40]" :page-size="page.size"
				 :total="page.total" layout="total, sizes, prev, pager, next, jumper">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import config from '@/config/index'
	import statisticsTime from './StatisticsTime'
	import {
		dataStatisProductSellAnalyzesell,
		dataStatisProductSellAnalyzeindex,
		productGroupListData
	} from '@/api/goods'
	export default {
		components: {
			statisticsTime
		},
		data() {
			return {
				keyWard:'',
				overLoading: false,
				Checks: [1, 2, 7, 13, 17],
				Statelist: [{
					value: 1,
					label: '销售中'
				},{
					value: 2,
					label: '已售罄'
				},{
					value: 3,
					label: '仓库中'
				}],
				StateId: '',
				groupId: '',
				groups: [{
					value: 1,
					label: '兵乓球'
				}],
				startDate1: '',
				endDate1: '',
				timeType1: '',
				timeType: '',
				startDate: '',
				endDate: '',
				unShowType:[2,6,7, 8, 9, 10],
				timeKeywords: ['较前一日', '较前一周', '较前一月', '较前7天', '较前30天'],
				listState: '0',
				listStateList: [{
						id: 0,
						value: '商品交易概况'
					},
					{
						id: 1,
						value: '商品交易分析'
					}
				],
				tableData: [{
					Id: 0,
					Imgurl: 'https://cdn.dkycn.cn/Images/mobelwgjImg.png',
					price: '2.23',
					title: '开始就单接受的考教师开始就单接受的考教师开始就单接受的考教师'
				}],
				page: {
					size: 20,
					current: 1,
					total: 0
				},
				CurrentOverview: {},
				PrevOverview: {},
				OrderBy: '',
				IsAsc: '',
				tableLoading: false,
				imgUrl: config.IMG_BASE,
				checkTooltip:[
					'统计筛选时间内，销量不为0的商品数量',
					'统计筛选时间内，所有商品支付金额总和/所有商品支付人数；支付人数去重',
					'统计筛选时间内，添加商品至购物车的人数，1人添加多个商品多次计1人',
					'统计筛选时间内，所有商品被加入购物车的累计件数',
					'统计筛选时间内，成功提交订单的人数，1人下单成功多次，计1人',
					'统计筛选时间内，成功提交的订单中，商品的购买件数总和',
					'统计筛选时间内，成功提交的订单中，商品的实付金额之和',
					'统计筛选时间内，将商品添加至购物车的人数/商品下单人数；人数去重',
					'统计筛选时间内，支付成功的人数，1人支付成功多个商品多次，计1人',
					'统计筛选时间内，成功支付的订单中，商品的购买件数总和',
					'统计筛选时间内，成功支付的订单中，商品的实付金额之和',
					'统计筛选时间内，支付人数/下单人数；人数去重',
					'统计筛选时间内，商品发起过退款申请的人数，1人发起多次，计1人',
					'统计筛选时间内，成功发起退款申请的退款单数量',
					'统计筛选时间内，成功退款的人数，1个成功退款多次，计1人',
					'统计筛选时间内，成功退款的退款单数量',
					'统计筛选时间内，退款成功的金额',
					'统计筛选时间内，商品成功退款单数/支付成功子订单数',
				]
			}
		},
		mounted() {
			this.getchangeData()
			this.analysisData()
			this.getGroup()
		},
		methods: {
			// 搜索事件
			inputFun() {
				this.analysisData()
			},
			// 商品交易概况
			async getchangeData() {
				try {
					this.overLoading = true
					let data = {
						TimeType: this.timeType,
						// StartDate: this.timeType == 0 ? '' : this.startDate,
						// EndDate: this.timeType == 0 ? '' : this.endDate,
						StartDate: this.startDate,
						EndDate:  this.endDate
					}
					let result = await dataStatisProductSellAnalyzesell(data)
					this.CurrentOverview = result.Result.CurrentProductSellAnalyzeSellOverview
				
					let obj={
						AddToCartMemberCount:0,
						AddToCartMemberCountAndSubmitOrderMemberCountConversionRate:0,
						AddToCartProductCount:0,
						ApplyRefundOrderCount:0,
						ApplyRefundOrderMemberCount:0,
						CustomerUnitPrice:0,
						PayOrderDetailCount:0,
						PayOrderMemberCount:0,
						PayOrderProductCount:0,
						PayOrderProductMoney:0,
						RefundOrderCount:0,
						RefundOrderMemberCount:0,
						RefundOrderProductMoney:0,
						RefundRate:0,
						SellProductNum:0,
						SubmitOrderMemberCount:0,
						SubmitOrderMemberCountAndPayOrderMemberCountConversionRate:0,
						SubmitOrderProductCount:0,
						SubmitOrderProductMoney:0
					}
						this.PrevOverview = result.Result.PrevProductSellAnalyzeSellOverview?result.Result.PrevProductSellAnalyzeSellOverview:obj
				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.overLoading = false
				}

			},
			gettoday(){
				let date = new Date()
				let year = date.getFullYear()
				let month = date.getMonth()+1>9?(date.getMonth()+1):('0'+(date.getMonth()+1))
				let day = date.getDate()>9?date.getDate():('0'+date.getDate())
				return [year,month,day].join('-')
			},
			// 商品交易分析
			async analysisData() {
				try {
					this.tableLoading = true
					let data = {
						TimeType:this.timeType1,
						Time: this.startDate1,
						KeyWord: this.keyWard,
						Skip: (this.page.current - 1) * this.page.size, // 略过数据
						Take: this.page.size, // 取的数据
						OrderBy: this.OrderBy,
						IsAsc: this.IsAsc,
						ProductGroupId: this.groupId,
						ProductState: this.StateId
					}
					// if(this.timeType1 ==1 || this.timeType1 == 0){
					// 	data.StartTime  = this.startDate1
					// 	data.EndTime  = this.endDate1
					// }else{
					// 	data.StartTime  = ''
					// 	data.EndTime  = ''
					// }
					if(this.timeType1 ==0){
						data.Time = ''
					}else if(this.timeType1 ==1){
						let poor = (new Date(this.gettoday()).getTime() - new Date(this.startDate1).getTime())/1000/60/60/24
						// console.log(poor)
						if(poor < 8){
							data.StartTime  = this.startDate1
							data.EndTime  = this.endDate1
						}
					}
					let result = await dataStatisProductSellAnalyzeindex(data)
					this.tableData=result.Result.Results
					this.page.total=result.Result.Total
				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.tableLoading = false
				}

			},
			// 获取分组
			async getGroup(){
				try{
					let data={
						Skip:0,
						Take:100
					}
					let result=await productGroupListData(data)
					this.groups=result.Result.Results					
				}catch(e){
					//TODO handle the exception
					console.log(e)
				}finally{
					
				}
				
			},
			sortChange(column, prop, order) {
				this.OrderBy = column.prop;
				if (column.order == "ascending") {
					// 正序
					this.IsAsc = true
				} else {
					this.IsAsc = false
				}
				this.page.current = 1;
				this.analysisData();
			},
			stateChange(){
				this.page.current=1
				this.analysisData()
			},
			rowKey(row) {
				return row.Id
			},
			// 公用计算的方法
			commentDatafun(CurrentData, PrevData) {
				return (Math.abs(CurrentData - PrevData) / PrevData* 100).toFixed(2) 
			},
			// 公用的箭头方法
			commentArrowsfun(CurrentData, PrevData) {
				let upOrDown = ""
				if (CurrentData != PrevData) {
					if (CurrentData > PrevData) {
						upOrDown = 'gorise'
					} else {
						upOrDown = 'decline'
					}
				} else {
					upOrDown = ''
				}
				return upOrDown
			},

			//跳转单品分析
			SingelAnaly(record) {
				this.$router.push({
					path: '/data/SingelAnalydetail',
					query: {
						SingelAnalyId: record.ProductId
					}
				});
			},
			handleSizeChange(val) {
				this.page.size = val
				this.analysisData()
			},
			handleCurrentChange(val) {
				this.page.current = val
				this.analysisData()
			},
			handleStructureCheck() {

			},
			handleClick() {
				this.page.current=1
				this.getchangeData()
				this.analysisData()
				console.log(this.listState, 'tab选中')
			},
			getRangeTime(startDate, endDate, timeType) {
				this.startDate = startDate
				this.endDate = endDate
				this.timeType = timeType
				this.currentKeyword = this.timeKeywords[timeType - 1]
				console.log(this.startDate, this.endDate, this.timeType, '商品交易概况')

				this.getchangeData()

			},
			getRangeTime1(startDate, endDate, timeType) {
				this.startDate1 = startDate
				this.endDate1 = endDate
				this.timeType1 = timeType
				this.page.current=1
				this.analysisData()
				console.log(this.startDate1, this.endDate1, this.timeType1, '商品交易分析')
			}
		}
	}
</script>

<style lang="less" scoped>
	.productDealbox {
		.contents {
			// background: #F5F5F5;
			// padding: 15px;
			font-size: 14px;

			.con_items {
				width: 93%;

				.item_detail {
					width: 18%;
					margin-bottom: 10px;
				}
			}
		}

		.infos {
			display: flex;
			align-items: center;

			.detailinfo {
				.title {
					overflow: hidden;
					display: -webkit-box; //将对象作为弹性伸缩盒子模型显示;
					text-overflow: ellipsis; //溢出部分用省略号代替
					-webkit-line-clamp: 2; //设置文本显示两行
					-webkit-box-orient: vertical; //从上到下排列子元素;
					white-space: normal;
				}
			}

		}
	}

	.overview {
		margin-top: 10px;
		padding: 15px;
		background-color: #fff;

		.interview {
			background-color: rgb(225, 238, 251);
			overflow: hidden;
			display: flex;
			flex-direction: row;

			.left {
				width: 80px;
				background-color: #409EFF;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				font-size: 14px;
			}

			.right {
				height: 100%;
				color: #606266;
				width: calc(100% - 80px);
				align-items: center;

				.item {
					padding: 25px;
					display: inline-block;
					width: 25%;
				}

				.item:nth-child(5n) {
					word-wrap: break-word;
					word-break: normal;
				}

				.lable {
					line-height: 20px;
					font-size: 12px;
				}

				.icon {
					font-size: 14px;
					color: #999;
					margin-left: 5px;
					cursor: pointer;
				}

				.num {
					line-height: 30px;
					font-size: 18px;
				}

				.compare {
					line-height: 20px;
					font-size: 12px;
				}

				.compare-hidden {
					display: none;
				}
			}
		}

		.flow {
			margin-top: 5px;
			background-color: rgb(225, 251, 231);

			.left {
				background-color: rgb(83, 172, 108);
			}
		}
	}
</style>
